



































import Component from "vue-class-component";
import {
  Maintenance,
  MaintenanceQuery,
  MaintenanceQueryVariables,
  UpdateMaintenanceMutation,
  UpdateMaintenanceMutationVariables,
} from "../types/gqlGeneratedPrivate";
import Vue from "vue";
import MaintenanceForm from "../components/app/maintenances/MaintenanceForm.vue";
import { api } from "../api";
import { alerts, apiClient } from "../utils";
import Button from "../components/commons/inputsAndControls/Button.vue";
import { Routes } from "../enums";

@Component({
  components: { Button, MaintenanceForm },
})
export default class PlannedMaintenanceFormPage extends Vue {
  maintenanceId = 0;

  Routes = Routes;
  formData: Maintenance | null = null;

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      MaintenanceQuery,
      MaintenanceQueryVariables
    >({
      ...api.maintenances.maintenance,
      variables: {
        id: parseInt(this.$route.params.id),
      },
    });
    if (data?.maintenance) {
      this.formData = data.maintenance;
      this.maintenanceId = parseInt(this.$route.params.id);
      this.initForm();
    }
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid && this.formData) {
      await this.sendForm(this.formData);
    }
  }

  async sendForm(formData: Maintenance): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      UpdateMaintenanceMutation,
      UpdateMaintenanceMutationVariables
    >({
      ...api.maintenances.updateMaintenance,
      variables: {
        id: formData.id,
        input: {
          description: formData.description,
          responsiblePersonId: formData.responsiblePersonId,
          plannedDate: formData.plannedDate,
          deviceId: formData.deviceId,
          type: formData.type,
        },
      },
    });
    if (data) {
      alerts.addSuccessAlert("UPDATE_PLANNED_MAINTENACE", "Uloženo");
    }
  }

  initForm(): void {
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }
}
